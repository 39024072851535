import React, { useEffect, useState } from 'react';
import { Avatar, Typography, Button, Card, Divider, notification, Space } from 'antd';
import Messages from './Messages';
import RoomInput from './RoomInput';
import { PassSocket } from '../types';
import Stats from './Stats';

const { Title } = Typography;
const { Meta } = Card;

function Room({ socket }: PassSocket) {
  const [room, setRoom] = useState(null);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (socket && room && room !== 'example') {
      notification.info({ duration: 1, message: `joining ${room}...` });
      socket.emit('joinRoom', room);
      const url = `/info/${room}`;
      console.log(process.env.REACT_APP_API + url);
      fetch(url)
        .then((result) => result.json())
        .then(setInfo);
    }
  }, [room]);

  return (
    <Card>
      {info ? (
        <Meta
          avatar={<Avatar size="large" src={info.broadcaster_image} />}
          description={info.title}
          title={
            <Space>
              <Title level={3}>{info.broadcaster_name}</Title>
              <Title level={5}>{info.game_name}</Title>
              <Button
                shape="circle"
                onClick={() =>
                  setRoom((prevRoom) => {
                    setInfo(null);
                    notification.info({ duration: 1, message: `leaving ${room}...` });
                    socket.emit('leaveRoom', prevRoom);
                    return null;
                  })
                }
              >
                X
              </Button>
            </Space>
          }
        />
      ) : (
        <Meta avatar={null} title="Select a channel" />
      )}
      <Divider />
      <Stats socket={socket} room={room} />
      <Divider />
      <Messages socket={socket} room={room} />
      {!room && <RoomInput socket={socket} setRoom={setRoom} />}
    </Card>
  );
}

export default Room;
