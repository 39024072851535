import React, { useEffect, useState } from 'react';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { ChannelEvents, PassChatRoom } from '../types';
import { Space, Statistic } from 'antd';

function Stats({ socket, room }: PassChatRoom) {
  const [info, setInfo] = useState<ChannelEvents>(new ChannelEvents());
  const [subject, setSubject] = useState(new Subject<ChannelEvents>());
  const [subscription, setSubscription] = useState<Subscription>(null);
  const [cheers, setCheers] = useState(0);
  const [subs, setSubs] = useState(0);
  const [gifts, setGifts] = useState(0);
  const [raids, setRaids] = useState(0);
  const [tiers, setTiers] = useState(0);

  useEffect(() => {
    if (!socket || !room) return;
    const rxmsgs = fromEvent(socket, 'channelevents');
    setSubject(new Subject<ChannelEvents>());
    subject.subscribe((event) => {
      setInfo(event);
      setCheers(event.cheers);
      setSubs(event.subs);
      setGifts(event.gifts);
      setRaids(event.raid);
      setTiers(event.tiers);
    });
    if (subscription) subscription.unsubscribe();
    setSubscription(rxmsgs.subscribe(subject));
  }, [room]);

  return (
    room &&
    info && (
      <Space>
        <Statistic title="cheers" value={cheers} />
        <Statistic title="subs" value={subs} />
        <Statistic title="gifts" value={gifts} />
        <Statistic title="raids" value={raids} />
        <Statistic title="tiers" value={tiers} />
      </Space>
    )
  );
}

export default Stats;
