import React from 'react';
import { PassSetRoom } from '../types';
import { Form, Input } from 'antd';
import './RoomInput.css';

const { Search } = Input;

export default function RoomInput({ socket, setRoom }: PassSetRoom) {
  const [form] = Form.useForm();

  return (
    <Form form={form}>
      <Form.Item name="room" label="Channel" rules={[{ required: true }]}>
        <Search
          placeholder="Enter a channel"
          allowClear
          enterButton="Enter"
          onSearch={(value) => {
            setRoom((prevRoom) => {
              socket.emit('leaveRoom', prevRoom);
              return value;
            });
          }}
        />
      </Form.Item>
    </Form>
  );
}
