import { io, Socket } from 'socket.io-client';

export class SingleSocket {
  private static socket: Socket;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static use() {
    if (!SingleSocket.socket) {
      SingleSocket.socket = io(process.env.REACT_APP_API, {
        transports: ['websocket'],
        reconnection: true,
        rejectUnauthorized: false,
      });
    }
    return SingleSocket.socket;
  }

  static close() {
    if (SingleSocket.socket) {
      SingleSocket.socket.off('message');
      SingleSocket.socket.off('connect');
      SingleSocket.socket.off('disconnect');
      SingleSocket.socket.off('connect_error');
      SingleSocket.socket.offAny();
      SingleSocket.socket.disconnect();
      SingleSocket.socket = null;
    }
  }
}
