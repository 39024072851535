import React, { useEffect, useState } from 'react';
import { fromEvent, Observable, Subject, Subscription, timer } from 'rxjs';
import './Messages.css';
import { ChatMessage, PassChatRoom } from '../types';
import { Typography, Timeline, Empty } from 'antd';

const { Text } = Typography;

function Messages({ socket, room }: PassChatRoom) {
  const [messages, setMessages] = useState([]);
  const [subject, setSubject] = useState(new Subject<ChatMessage>());
  const [subscription, setSubscription] = useState<Subscription>(null);
  const [remover, setRemover] = useState<Observable<any>>(null);

  useEffect(() => {
    if (remover) {
      const sub = remover.subscribe(() => {
        setMessages((prev) => {
          if (prev.length <= 1) {
            sub.unsubscribe();
            return [];
          }
          return [...prev.slice(1)];
        });
      });
    }
  }, [remover]);

  useEffect(() => {
    if (!socket) return;
    if (!room) setRemover(timer(0, 500));
    else setRemover(null);
    const rxmsgs = fromEvent(socket, 'message');
    setSubject(new Subject<ChatMessage>());
    subject.subscribe((message) => {
      if (messages.some((m) => m.id === message.id)) return; // ignore duplicated messages
      setMessages((prev) => {
        if (prev.length > 10) return [...prev.slice(1, 10), message];
        else return [...prev, message];
      });
    });
    if (subscription) subscription.unsubscribe();
    setSubscription(rxmsgs.subscribe(subject));
  }, [room]);

  return messages.length === 0 ? (
    <Empty description="No messages yet" />
  ) : (
    <Timeline>
      {messages.map((message: ChatMessage, index: number) => (
        <Timeline.Item key={message.id || index} color={message.color}>
          <Text strong>{message.name}:</Text>
          &nbsp;
          <Text>{message.text}</Text>
        </Timeline.Item>
      ))}
    </Timeline>
  );
}

export default Messages;
