import React from 'react';
import { notification } from 'antd';
import { PassNamespace } from './types';

export default class ErrorBoundary extends React.Component<PassNamespace> {
  openNotification = (title, body) => {
    notification.error({
      message: `${this.props.namespace} error: ${title}`,
      description: body,
    });
  };

  componentDidCatch(error, errorInfo) {
    this.openNotification(error.message, errorInfo);
  }

  render() {
    return this.props.children;
  }
}
