import React, { useEffect, useState } from 'react';
import { Image, Layout, Menu, notification } from 'antd';
import { Link, Route, Switch } from 'react-router-dom';
import Home from './home/Home';
import './App.css';
import LoginButton from './auth/login';
import ErrorBoundary from './ErrorBoundary';
import { Socket } from 'socket.io-client';
import { SingleSocket } from './messages/SingleSocket';
import { fromEvent } from 'rxjs';

const { Header, Content } = Layout;

export default function App() {
  const [socket, setSocket] = useState<Socket>();

  const checkSocket = () => {
    const s = SingleSocket.use();
    fromEvent(s, 'connect').subscribe(() => notification.success({ duration: 1, message: 'Socket connected' }));
    fromEvent(s, 'disconnect').subscribe((reason) => notification.warn({ message: `Socket disconnected: ${reason}` }));
    fromEvent(s, 'connect_error').subscribe((error) => notification.error({ message: error }));
    setSocket(s);
  };

  useEffect(() => {
    checkSocket();
    return () => SingleSocket.close();
  }, []);

  return (
    <Layout>
      <Header className="header">
        <Menu theme="dark" mode="horizontal">
          <Menu.Item key="1">
            <Link to="/">
              <Image preview={false} src="chemo_logo.png" className="logo" alt="Chemo Chat" />
            </Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/about">About</Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/pricing">Pricing</Link>
          </Menu.Item>
          <ErrorBoundary namespace="Auth0">
            <LoginButton />
          </ErrorBoundary>
        </Menu>
      </Header>
      <Content>
        <Switch>
          <Route path="/about">
            <p>This is the about page</p>
          </Route>
          <Route path="/">
            <ErrorBoundary namespace="Home">
              <Home socket={socket} />
            </ErrorBoundary>
          </Route>
        </Switch>
      </Content>
    </Layout>
  );
}
