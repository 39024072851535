import React from 'react';
import { Col, Row } from 'antd';
import { PassSocket } from '../types';
import Room from '../messages/Room';

function Home({ socket }: PassSocket) {
  return (
    <Row>
      <Col span={4} />
      <Col span={16}>
        <Room socket={socket} />
      </Col>
      <Col span={4} />
    </Row>
  );
}

export default Home;
