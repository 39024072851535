import { Socket } from 'socket.io-client';

export type PassSocket = {
  socket: Socket;
};

export type PassSetRoom = PassSocket & {
  setRoom: (r: (prevRoom) => string) => void;
};

export type PassNamespace = {
  namespace?: string;
};

export type PassChatRoom = PassSocket & {
  room: string;
};

export type ChatMessage = {
  id: string;
  room: string;
  roomId: string;
  text: string;
  badges: any[];
  name: string;
  color: string;
  userId: string;
  isMod: boolean;
  time: string;
};

export class ChannelEvents {
  room: string;
  cheers: number;
  subs: number;
  raid: number;
  gifts: number;
  tiers: number;
}
