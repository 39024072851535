import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Menu, Avatar } from 'antd';
const { SubMenu } = Menu;

function LoginButton() {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [, setUserMetadata] = useState(null);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = 'dev-jh6lpfgd.us.auth0.com';

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: 'read:current_user update:current_user_metadata',
        });

        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();

        setUserMetadata(user_metadata);
      } catch (e) {
        console.error(e.message);
      }
    };
    getUserMetadata().then();
  }, [getAccessTokenSilently, user?.sub]);

  if (isLoading) return <Menu.Item key="3">Loading...</Menu.Item>;

  if (!isAuthenticated)
    return (
      <Menu.Item key="3" onClick={() => loginWithRedirect()}>
        Log In
      </Menu.Item>
    );

  return (
    <SubMenu key="3" icon={<Avatar size={48} src={user.picture} alt="" />} title={user.nickname}>
      <Menu.ItemGroup title="My Info">
        <Menu.Item key="4">Settings</Menu.Item>
        <Menu.Item key="5" onClick={() => logout({ returnTo: window.location.origin })}>
          Log Out
        </Menu.Item>
      </Menu.ItemGroup>
    </SubMenu>
  );
}

export default LoginButton;
